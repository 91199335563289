@import "./veriables.scss";
/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}*/

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  min-height: 100%;
  /* flex: auto; */
  display: flex;
  flex-flow: column wrap;
}
i {
  &.custom-arrow {
    /*border: solid black;
    border-width: 0 0.5rem 0.5rem 0;
    display: inline-block;
    padding: 0.5rem;*/
    border: solid black;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.2rem;
    margin-bottom: 0.1rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    &.right {
      margin-bottom: 0rem;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    &.left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }

    &.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    &.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}
.btn-sqaure-image {
  background: white;
  padding: 0.2rem;
  text-align: center;
  display: inline-flex;
  margin-right: 0.5rem;
}
.panel-back-to-dashbaord {
  button {
    &#backToDashbaord {
      background: url("./shared/icons/outline-arrow_back-24px.svg") no-repeat
        left center;
      background-size: 1rem 1rem;
      padding-left: 1.5rem;
    }
  }
}
.delete-icon {
  background: url("./shared/icons/trash.svg") no-repeat center center;
  background-size: auto 80%;
  height: 1.2rem;
  width: auto;
}
.edit-icon {
  background: url("./shared/icons/edit-table.svg") no-repeat center center;
  background-size: auto 80%;
  height: 1.2rem;
  width: auto;
}
.swap-icon {
  background: url(./shared/icons/swap.png) no-repeat center center;
  background-size: auto 18px;
  height: 1.2rem;
  width: auto;
}
.checkbox-verified-icon {
  background: url("./shared/icons/verified_green.svg") no-repeat center center;
  background-size: auto 80%;
  height: 1.2rem;
  width: auto;
}
/* btn-square used in side panel call and whats app icon */
.buttons-block {
  display: flex;
  .btn-square {
    height: 3rem;
    width: 3rem;
    display: grid;
    align-items: center;
    text-align: -webkit-center;
    img {
      width: 80%;
    }
    &.whatsapp-button {
      img {
        width: 100%;
      }
    }
  }
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  &.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  &.delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }

  &.delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }

  &.delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
  }

  &.delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
  }

  &.delay-5s {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
  }

  &.fast {
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
  }

  &.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
  }

  &.slow {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }

  &.slower {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  @-webkit-keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  &.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }
  @-webkit-keyframes flash {
    from,
    50%,
    to {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0;
    }
  }

  @keyframes flash {
    from,
    50%,
    to {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0;
    }
  }

  &.flash {
    -webkit-animation-name: flash;
    animation-name: flash;
  }
}
@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}
*:disabled,
*.disabled {
  cursor: not-allowed;
}
.ellipsisText {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.payment-details {
  list-style: none;
  padding-top: 0.5rem;
  padding-left: 0rem;
}
.payment-Success {
  color: green;
}
.payment-Failed {
  color: red;
}
@media (max-width: 768px) {
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    position: absolute;
    top: 52px;
    left: 0px;
    background: rgb(11, 34, 61);
    z-index: 1;
    width: 100%;
    padding: 0.5rem 1.5rem;
    .dropdown-menu {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 1);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .dropdown-item {
        color: white;
      }
    }
  }
  /*.navbar-collapse {
      position: absolute;
      top: 54px;
      left: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      width: 100%;
  }
  .navbar-collapse.collapsing {
      height: auto;
      -webkit-transition: left 0.3s ease;
      -o-transition: left 0.3s ease;
      -moz-transition: left 0.3s ease;
      transition: left 0.3s ease;
      left: -100%;
  }
  .navbar-collapse.show {
      left: 0;
      -webkit-transition: left 0.3s ease-in;
      -o-transition: left 0.3s ease-in;
      -moz-transition: left 0.3s ease-in;
      transition: left 0.3s ease-in;
  }*/
}
.table th,
.table td {
  padding: 0.3rem;
}

.api-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.9);
  left: 0;
  top: 0;
  z-index: 10000;
  flex-direction: column;
  .api-loader-text{
    color: white;
    padding: 10px;
    font-style: italic;
  }
  .animation {
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(255, 255, 255, 0.8);
    border-left: 1.1em solid var(--defaultLoaderPrimary);
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
      @media (max-width:480px){
        width: 4em;
        height: 4em;
        border: 0.7em solid rgba(255, 255, 255, 0.8);
        border-left: 0.7em solid  var(--defaultLoaderPrimary);
      }
  }
}
.anchor-style{
  color: #1670da !important;
  cursor: pointer !important;
  max-width: 350px;
  @media(max-width:1024px){
    max-width: 90%;
  }
}
.anchor-style:hover{
  color: #0f4c95 !important;
  text-decoration: underline !important;
}
#threedsChallengeRedirect .row{
  background-color: white;
}

.custom-scroll, #eeChatBotChatWindow, #__eechat-chips-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow: auto; /* or overflow: scroll; */
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 0.125rem;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar {
    width: 0.3rem;
  }
  &.no-scroll {
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
  }
}

.btn:disabled.disabled{
  opacity: 0.5;
}

.Toastify {
  .Toastify__toast-container {
    .Toastify__toast-body {
      margin-right: 12px;
    }
  }
}